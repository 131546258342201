// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
const firebaseConfig = {

  apiKey: "AIzaSyAc25HsFBlYQ4K3L0sp_p5v0GvE0q_9rCI",

  authDomain: "billrk.firebaseapp.com",

  databaseURL: "https://billrk-default-rtdb.firebaseio.com",

  projectId: "billrk",

  storageBucket: "billrk.appspot.com",

  messagingSenderId: "388799440205",

  appId: "1:388799440205:web:d28613add464fb44f60304",

  measurementId: "G-MDV5R2YC9W"

};





// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export {app}